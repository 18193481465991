<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table :headers="header" :items="recordings" hide-default-footer>
          <template v-slot:item.name="{ item }">
            <div>
              <a
                href="javascript:void(0)"
                style="cursor: text"
                class="text-decoration-none"
              >
                {{ item.name }}
              </a>
            </div>
          </template>
          <template v-slot:item.serial_number="{ index }">
            <div>
              {{ index + 1 }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon
                @click="copyLink(item.id)"
                color="teal"
                class="rounded-xl pa-1 ml-2 grey lighten-3"
                title="copy"
              >
                mdi-link-variant
              </v-icon>
              <v-text-field
                style="opacity: 0; position: absolute"
                :value="item.record_link"
                :id="item.id"
              ></v-text-field>
              <v-icon
                color="error"
                class="rounded-xl pa-1 ml-2"
                style="background: #ffe6e6"
                @click="confirmDelete(item)"
                title="Delete"
              >
                mdi-trash-can-outline
              </v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="float-start" v-if="pagination.total > 1">
          <v-pagination
            v-model="pagination.page"
            :total-visible="7"
            :length="pagination.total"
            @input="nextPage"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="400">
      <v-card class="pa-4 rounded-xl" flat>
        <v-toolbar class="error--text" flat>
          <v-spacer></v-spacer>
          <span class="text-h6">Confirm Delete!!!</span>
          <v-spacer></v-spacer>
          <v-icon color="error" size="18" @click="closeDialog">
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-card-text class="error--text">
          If you delete this label, All your recorded and received videos
          associated with this label will be lost.
        </v-card-text>
        <div>
          <strong class="error--text">To continue, Type DELETE</strong>
          <v-text-field
            v-model="confirm_delete"
            solo
            dense
            flat
            rounded
            background-color="secondary"
          ></v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error rounded-xl px-10"
            @click="deleteRequestedLink"
            large
            depressed
            :disabled="disabledBtn"
          >
            DELETE
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <new-request></new-request>
    <v-snackbar v-model="snackbar" bottom right color="success darken-2">
      {{ success_msg }}
    </v-snackbar>
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
import NewRequest from "../../components/NewRequest";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "requestedLinks",
  components: {
    NewRequest,
  },
  data() {
    return {
      recordings: [],
      toast: new ResponseHelper(),
      pagination: {
        page: 1,
        total: 1,
      },
      search: "",
      timerId: null,
      timer: 0,
      dialog: false,
      confirm_delete: null,
      header: [
        {
          text: "S/N",
          value: "serial_number",
          orderable: false,
          sortable: false,
        },
        { text: "Label", value: "name" },
        { text: "Link", value: "record_link" },
        { text: "Number of Recordings", value: "recordings_count" },
        { text: "Actions", value: "actions" },
      ],
      snackbar: false,
      success_msg: "",
    };
  },
  created() {
    this.requestedLinks();
    this.$root.$on("search", (data) => {
      this.timer = 1;
      this.timerId = setTimeout(() => {
        if (this.timer > 0) {
          this.search = data;
          this.pagination.page = 1;
          this.requestedLinks();
        }
        this.timer = 0;
      }, 2000);
    });
    this.$root.$on("reload-links", () => {
      this.requestedLinks();
    });
  },
  methods: {
    ...mapMutations("recordings", ["SHOW_DETAILS"]),
    ...mapActions("recordings", ["requested_links", "delete_requested_link"]),
    ...mapMutations("auth", ["LOADING"]),
    async requestedLinks(page) {
      if (!page) {
        page = "?page=" + this.pagination.page + "&search=" + this.search;
      }
      this.LOADING(true);
      try {
        const response = await this.requested_links(page);
        this.recordings = response.data.data;
        this.pagination.page = response.data["current_page"];
        this.pagination.total = response.data["last_page"];
        this.LOADING(false);
      } catch (e) {
        this.LOADING(false);
        this.toast.sendError(e);
      }
    },
    nextPage() {
      let page = "?page=" + this.pagination.page + "&search=" + this.search;
      this.requestedLinks(page);
    },
    async confirmDelete(record) {
      this.record = record;
      this.dialog = true;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.confirm_delete = null;
      this.dialog = false;
    },
    async deleteRequestedLink() {
      this.closeDialog();
      this.LOADING(true);
      try {
        const response = await this.delete_requested_link(this.record.id);
        this.LOADING(false);
        this.nextPage();
        this.toast.sendSuccess(response);
      } catch (e) {
        this.LOADING(false);
        this.toast.sendError(e);
      }
    },
    copyLink(id) {
      let link = document.getElementById(id);
      link.select();
      document.execCommand("copy");
      this.success_msg = "Link copied to clipboard";
      this.snackbar = true;
    },
  },
  computed: {
    disabledBtn() {
      return this.confirm_delete !== "DELETE";
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped></style>
